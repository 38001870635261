import React from "react";

export default function Img({app, nomeImagem, height = "h-6"}) {
  return (
    <img
      src={`/assets/${nomeImagem || app?.replace(" ", "-") + ".png"}`}
      alt={`Logo ${app}`}
      title={app}
      className={`w-auto ${height} m-1 text-white`}
    />
  );
}
